
import RecordingsExportDialog from "@/components/recordings/RecordingsExportDialog"
import RecordingsXrayExportDialog from "@/components/recordings/RecordingsXrayExportDialog"
import RecordingsDateTimePicker from "@/components/recordings/RecordingsDateTimePicker"
import RecordingsEvents from "@/components/recordings/events/RecordingsEvents"
import EvercamObjectDetection from "@evercam/shared/components/EvercamObjectDetection"
import AskChatGpt from "@evercam/shared/components/AskChatGpt"
import Segmentation from "@evercam/shared/components/Segmentation"
import DepthAnalysis from "@evercam/shared/components/DepthAnalysis"
import { useSnapshotStore } from "@/stores/snapshots"
import { RecordingsTabs, useRecordingsStore } from "@/stores/recordings"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { camelizeKeys } from "humps"
import { AnalyticsEvent, BrainTool } from "@evercam/shared/types"
import { useLayoutStore } from "@/stores/layout"

export default {
  name: "RecordingsSidebar",
  components: {
    RecordingsExportDialog,
    RecordingsDateTimePicker,
    RecordingsEvents,
    EvercamObjectDetection,
    AskChatGpt,
    Segmentation,
    DepthAnalysis,
    RecordingsXrayExportDialog,
  },
  props: {
    isSnapshotAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoadingLatestSnapshot: false,
      calendarSelectedTimestamp: null,
      canSetStartOfHour: true,
      isJpegEnabled: false,
      imageDimension: this.currentImageDimensions,
      selectedTool: null,
      key: 0,
      BrainTool,
      tabs: RecordingsTabs,
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useRecordingsStore,
      useCameraStore,
      useLayoutStore
    ),
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    currentImageDimensions() {
      return {
        height: this.recordingsStore.getCurrentImageDimensions().height,
        width: this.recordingsStore.getCurrentImageDimensions().width,
      }
    },
    formattedSelectedTimestamp() {
      return this.$moment(this.recordingsStore.selectedTimestamp)
        .tz(this.timezone)
        .format("YYYY-MM-DD HH:mm:ss")
    },
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
    isBrainToolTab() {
      return this.recordingsStore.selectedTab === RecordingsTabs.BRAIN_TOOL
    },
    showEvercamObjectDetection() {
      return this.selectedTool === BrainTool.DETECTIONS && this.isBrainToolTab
    },
    showAskChatGpt() {
      return (
        this.$permissions.user.is.admin() &&
        this.selectedTool === BrainTool.CHATGPT &&
        this.isBrainToolTab
      )
    },
    showSegmentation() {
      return (
        this.$permissions.user.is.admin() &&
        this.selectedTool === BrainTool.SEGMENTATION &&
        this.isBrainToolTab
      )
    },
    showDepthAnalysis() {
      return (
        this.$permissions.user.is.admin() &&
        this.selectedTool === BrainTool.DEPTH_ANALYSIS &&
        this.isBrainToolTab
      )
    },
    brainTools() {
      const baseTools = [
        {
          label: "Object Detection",
          value: BrainTool.DETECTIONS,
          eventId: AnalyticsEvent.aiToolSelectObjectDetection,
        },
      ]

      const experimentalTools = [
        {
          label: "Ask ChatGPT",
          value: BrainTool.CHATGPT,
          eventId: AnalyticsEvent.aiToolSelectAskChatGpt,
        },
        {
          label: "Segmentation",
          value: BrainTool.SEGMENTATION,
          eventId: AnalyticsEvent.aiToolSelectSegmentation,
        },
        {
          label: "Depth Analysis",
          value: BrainTool.DEPTH_ANALYSIS,
          eventId: AnalyticsEvent.aiToolSelectDepthAnalysis,
        },
      ]

      return this.$permissions.user.is.admin()
        ? [...baseTools, ...experimentalTools]
        : baseTools
    },
  },
  watch: {
    "recordingsStore.isXrayActive"(isActive) {
      if (isActive) {
        this.recordingsStore.selectedTab = RecordingsTabs.XRAY
        this.recordingsStore.isBrainToolActive = false

        return
      }
      if (!this.recordingsStore.isBrainToolActive) {
        this.recordingsStore.selectedTab = RecordingsTabs.RECORDINGS
      }
    },
    isBrainToolTab(isBrainTool) {
      if (isBrainTool) {
        this.recordingsStore.isBrainToolActive = true
        this.recordingsStore.changeXrayVisibility(false)
      } else {
        this.recordingsStore.isBrainToolActive = false
      }
    },
    "layoutStore.isRightSidebarVisible"() {
      this.onResize()
    },
    "layoutStore.rightSidebarWidth"() {
      this.onResize()
    },
    "recordingsStore.isLive"() {
      if (this.recordingsStore.isLive) {
        this.canSetStartOfHour = false
        this.calendarSelectedTimestamp =
          this.snapshotStore.latestSnapshotTimestamp
        this.recordingsStore.selectedTab = RecordingsTabs.RECORDINGS
      }
    },
    calendarSelectedTimestamp(timestamp) {
      if (!this.cameraStore.selectedCamera && !timestamp) {
        return
      }
      if (this.canSetStartOfHour) {
        this.recordingsStore.selectedTimestamp = this.$moment(timestamp)
          .tz(this.timezone)
          .startOf("hour")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else {
        this.recordingsStore.selectedTimestamp = timestamp
        this.canSetStartOfHour = true
      }
      this.$analytics.saveEvent(AnalyticsEvent.recordingsSelectDate, {
        timestamp,
      })
    },
    "recordingsStore.selectedXrayTimestamp"(timestamp) {
      if (timestamp) {
        this.$analytics.saveEvent(AnalyticsEvent.xarySelectDate, {
          timestamp,
        })
      }
    },
  },
  async created() {
    this.onInitCalendar()
    this.$root.$on("is-jpeg-enabled", (value) => (this.isJpegEnabled = value))
  },
  beforeDestroy() {
    if (
      this.recordingsStore.selectedTab === RecordingsTabs.BRAIN_TOOL ||
      this.recordingsStore.selectedTab === RecordingsTabs.XRAY
    ) {
      this.recordingsStore.selectedTab = RecordingsTabs.RECORDINGS
    }
  },
  mounted() {
    this.$addEventListener("resize", this.onResize)
  },
  methods: {
    onResize() {
      this.$setTimeout(() => {
        this.imageDimension = {
          height: this.recordingsStore.getCurrentImageDimensions()?.height,
          width: this.recordingsStore.getCurrentImageDimensions()?.width,
        }
      }, 500)
    },
    selectTool(tool) {
      this.selectedTool = tool.value
      const eventId = this.brainTools.find(
        (item) => item.value === tool.value
      )?.eventId
      this.$analytics.saveEvent(eventId)
      this.recordingsStore.selectedTab = RecordingsTabs.BRAIN_TOOL
    },
    async onInitCalendar() {
      this.canSetStartOfHour = false
      let timestamp = this.$route.params?.timestamp
      if (timestamp && this.$moment(timestamp).isValid()) {
        this.calendarSelectedTimestamp = timestamp

        return
      }
      if (
        !this.snapshotStore.latestSnapshotTimestamp ||
        this.cameraStore.isCameraOnline
      ) {
        await this.snapshotStore.updateLatestSnapshot(this.routeCameraExid)
      }
      this.calendarSelectedTimestamp =
        this.snapshotStore.latestSnapshotTimestamp
    },
    enableXray() {
      this.recordingsStore.changeXrayVisibility(true)
    },
    onSelectOldest() {
      this.canSetStartOfHour = false
      this.calendarSelectedTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp
      this.$analytics.saveEvent(AnalyticsEvent.recordingsSelectOldestDate)
    },
    async onSelectLatest() {
      if (this.cameraStore.isCameraOnline) {
        this.isLoadingLatestSnapshot = true
        await this.snapshotStore.updateLatestSnapshot(
          this.cameraStore.selectedCameraExid
        )
        this.isLoadingLatestSnapshot = false
      }
      this.canSetStartOfHour = false
      this.calendarSelectedTimestamp =
        this.snapshotStore.latestSnapshotTimestamp
      this.$analytics.saveEvent(AnalyticsEvent.recordingsSelectLatestDate)
    },
    selectXrayOldestDate() {
      this.recordingsStore.selectedXrayTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp
      this.$analytics.saveEvent(AnalyticsEvent.xarySelectOldestDate)
    },
    selectXrayLatestDate() {
      this.recordingsStore.selectedXrayTimestamp =
        this.snapshotStore.latestSnapshotTimestamp
      this.$analytics.saveEvent(AnalyticsEvent.xarySelectLatestDate)
    },
    disableFirstLastButton(value) {
      if (!this.calendarSelectedTimestamp || !this.cameraStore.selectedCamera) {
        return true
      }

      if (value === "oldest") {
        return (
          this.calendarSelectedTimestamp ===
          this.snapshotStore.oldestSnapshotTimestamp
        )
      }

      return (
        this.calendarSelectedTimestamp ===
        this.snapshotStore.latestSnapshotTimestamp
      )
    },
    disableXrayFirstLastButton(value) {
      let timestamp = this.$moment(this.recordingsStore.selectedXrayTimestamp)
        .tz(this.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      if (value === "oldest") {
        return timestamp === this.snapshotStore.oldestSnapshotTimestamp
      } else {
        return timestamp === this.snapshotStore.latestSnapshotTimestamp
      }
    },
  },
}
